@import 'breakpoints'; 

 .navwrapper {
  height: 120px;
  width: 99vw;
  position: absolute;
  margin: 0;
  top: 0;
  left: 0;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
}
.logoCol{
  padding-left: 4rem;
  .navLogo{
    width: 20%;
    flex-shrink: 1.4;
  }
}
.linkCol{
  align-self: center;
  .col{
    flex-basis: 18%;
    flex-grow: 1;
  }
  .linkRow{
    justify-content: space-around;
    align-content: center;
    align-items: center;
  }

  .navlinks {
    word-wrap: break-word;
    font-size: 1.3vw;
    color: #080c11;
    font-weight: 300;
    transition: all 600ms ease-in-out;
    z-index: 500;
    mix-blend-mode: normal;
   
    &:hover{
    font-weight: 500;
    }
  }
}
 
/**Loading Com**/
.bgLoading{
  background: #dfdcee url('../images/loading1.gif') no-repeat center center / contain;
  background-blend-mode: multiply;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
  padding: 2rem 0.5rem;

  h1{
    margin-top: 4rem;
    width: 70%;
    max-height: 80vh;
    font-size: 5.2vw;
    color: #261b3b;
   }
}


/*************FOOTER**********************//*************FOOTER**********************/
/*************FOOTER**********************//*************FOOTER**********************/

.container-sm{
  background: rgba(250, 241, 255, 0.808) url('../images/footer-small.svg') no-repeat center top/1600px;
  background-blend-mode: multiply;
  height: 100%;
  position: relative;
  width: 100vw;
  max-width: 100vw;
  padding: 1rem 1rem 2rem 1rem;
  margin: 0;

  @include media ("screen", "<=phone") {
    background-size: cover;
    padding: 1.5rem 0.4rem;
    height: 100%;
  }
} 
.footer_row-sm{
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  margin-top: 0.5rem;
  justify-content: center;
  align-content: space-between;
  align-items: center;

  @include media ("screen", "<=phone") {
    padding: 0;
    margin-top: 0;
  }
}
.first-sm{
  height: 100%;
  width: 100%;
  padding: auto 2rem auto 3rem;

  @include media ("screen", "<=phone") {
    margin-bottom: 1.5rem;
  }
  .fads{
    padding: 1rem 1rem 0 1rem;
    h4{
      font-weight: 400;
      font-size: 1.4rem;
      color: #1A2632;

      @include media ("screen", "<=phone") {
        font-size: 6.5vw;
        line-height: 150%;
        margin-bottom: 0.5rem;
      }
    }
    span{
      font-weight: 200;
      position: relative;
      font-size: 1.1rem;
      color: #1A2632;
      margin-bottom: 1rem;

      @include media ("screen", "<=phone") {
        font-size: 5.5vw;
        margin-bottom: 0.5rem;
      }
    }
}}
.media{
  justify-content: flex-start;
  align-self: space-between;
  align-content: space-between;
  width: 40%;

  @include media ("screen", "<=phone") {
    display: none;
  }

  .social-sm{
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-top: 1rem;
    img{
      height: 30px;
      width: 30px;
    }
    .facebook{
      width: 20px;
    }

  }
}
.second-sm{
  width: 100%;
  padding-right: 3.5rem;;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  text-transform: uppercase;

  @include media ("screen", "<=phone") {
    margin-top: 1.5rem;
    padding-right: 1;
  }

  .contact{
    float: right;
    text-align: right;

    @include media ("screen", "<=phone") {
      float: left;
      text-align: left;
    }
  
    h3{
      letter-spacing: 0.2rem;
      font-weight: 600;
      color: #421750;
      font-size: 1.4rem;
      margin-bottom: 1.5rem;

      @include media ("screen", "<=phone") {
        margin-bottom: 1rem;
        padding-bottom: 0.3rem;
        border-bottom: 0.5px solid #42175042;
      }
    }
    a{
      letter-spacing: 0.2rem;
      font-size: 1.1rem;
      font-weight: 400;
      color: #41076d;

      @include media ("screen", "<=phone") {
        font-size: 1rem;
        letter-spacing: 0.1rem;
      }
    }
  }  
}

