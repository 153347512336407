.mobilenavWrapper{
    position: fixed;
    padding-top: 0.5rem;
    z-index: 800;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    width: 100vw;
    backdrop-filter: blur(3px);

    .smLogo{
        position: fixed;
        margin: 1.5rem;
        top: 1rem;

        img{
        width: 23%;
        }
    }

  .nav {
    width: 100vw;
    position: absolute;
    padding-top: 0.5rem;
    z-index: 100;
    visibility: hidden;
    background-color: #867091;
    will-change: transform;

    .showmenu & {
        background-color: #917d9b;
        visibility: visible;
        position: relative;
        z-index: 600;
    }
  }
  
  .nav-content {
    width: 100vw;
    height: 102vh;
    overflow: hidden;
    color:#fff4e4;

    .nav-inner {
        height: 100vh;
        margin: 7rem 1rem 2rem 0.5rem;
    }
        
    .nav-item {
        list-style: none;
        font-size: 1.7rem;
        color:#eae0fd;
        font-family: "Vipnagorgialla", "Raleway", sans-serif;
        width: 100%;

        li{
            margin-bottom: 1.4rem;
            text-decoration: underline;
            text-decoration-color: #d0b5ee;
        }
    }
    .nav-info{
        margin: 3rem auto 1rem auto;
    }
    .nav-info-item {
        color:#ede3ff;
        list-style: none;
        font-size: 1rem;
        line-height: 170%;

        a{
            color:#fff4df;
        }
    }
    .smNavsocial{
        margin: 2.5rem auto auto 2.6rem;
        width: 75%;
        .smSocial{
            margin-right: 3rem;
            img{
               height: 30px;
            }
        }
    }
  } 
}
.burger {
    margin-top: 2.5rem;
    position: absolute;
    right: 2.5rem;
    top: 1.2rem;
    display: grid;
    justify-self: end;
    z-index: 200;
    cursor: pointer;

        &__line {
            margin-bottom: 5px;
            width: 30px;
            height: 3px;
            background: rgb(129, 110, 151);
            border-radius: 2px;
            transform-origin: left center;
            transition: all 0.4s ease-in-out;
            filter: contrast(150%) brightness(1.4);

            &:first-of-type {
                .open & {
                    transform: rotate(44deg) translate3d(-2px,0,0);
                    background-color: #eae0fd;
                    opacity: 0.8;
                }
            }

            &:nth-of-type(2) {
                width: 18px;
                .open & {
                    opacity: 0;
                }
            }

            &:last-of-type {
                .open & {
                    transform: rotate(-44deg) translate3d(-4px, 0, 0);
                    background-color: #eae0fd;
                    opacity: 0.8;
                }
            }
        }
    }
    
  