@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;800&display=swap");
@import url("https://allfont.net/allfont.css?fonts=vipnagorgialla");

html,
body {
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  scrollbar-width: none;
}
::-webkit-scrollbar {
  display: none; }

::-webkit-scrollbar-track {
    background: transparent;
}
::-webkit-scrollbar-thumb {
  background: transparent; 
}
body {
  width: inherit;
  height: 100%;
  color: #1A2632;}

  .row{
    margin: 0;
  }
.active {
  color: #621c72; 
mix-blend-mode: difference;}


p, h3, h4, h5 {
  font-size: 100%;
  vertical-align: baseline;
  font-family: 'Montserrat', sans-serif;
  white-space: normal;
  overflow-wrap: break-word; }

h1, h2 {
  font-size: 100%;
  vertical-align: baseline;
  font-family: "Vipnagorgialla", "Raleway", sans-serif;
  mix-blend-mode: normal; }

a {
  color: #2c0330;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  position: relative;
  z-index: 400;
  }

a:hover {
  color: #623f6b; 
  text-decoration: none;
}

  @media only screen and (max-width: 500px) {
    body {
      width: 100vw;
      overflow-x: inherit;
    }
  }